<template>
  <v-container fluid class="ma-0 pa-0">
    <main-layout v-if="isMobile" />
    <continue-register v-if="notRegistered"></continue-register>

    <!-- isMobile -->

    <div class="mx-4" v-if="isMobile">
      <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="6" md="4" class="pa-0">
          <h2 class="ml-2 mb-4 mt-2 font-weight-bold">
            {{ $t("login.title") }}
          </h2>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="6" md="4" class="pa-0">
          <v-text-field
            :label="$t('field.email')"
            color="primary"
            :rules="emailRules"
            validate-on-blur
            required
            outlined
            v-model="user.email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="6" md="4" class="pa-0">
          <v-text-field
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            :hint="$t('validation.password')"
            :label="$t('field.password')"
            outlined
            hide-details="auto"
            @keypress.enter="onLogin"
            v-model="user.password"
          >
            <v-icon slot="append" color="primary" @click="show1 = !show1">{{
              show1 ? "mdi-eye" : "mdi-eye-off"
            }}</v-icon>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="10" sm="6" md="4" class="pa-0 mo-0 mt-2">
          <a @click="redirectChangePassword" style="opacity: 0.6">{{
            $t("password.forget")
          }}</a>
        </v-col>
      </v-row>
    </div>
    <v-row class="mx-4 my-6" justify="center" v-if="isMobile">
      <!--    <v-col cols="6" sm="3" md="2" class="pa-0">
 <template v-slot:label>
        <div class="primary--text text-subtitle-1">
          {{ $t("login.rememberme") }}
        </div>
  </template> 
      </v-col> -->
      <v-col cols="10" sm="3" md="2" class="pa-0 mt-2 mr-0 text-center">
        <div>
          <v-btn
            color="secondary"
            style="text-capitalize text-h6 font-weight-light"
            block
            large
            rounded
            @click.prevent="onLogin"
          >
            {{ $t("login.title") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- <v-row class="mt-6 align-center" justify-sm="center">
      <v-col cols="4" sm="3" md="2">
        <v-divider class="mx-4 width"></v-divider> </v-col
      >{{ $t("login.access") }}
      <v-col cols="4" sm="3" md="2"
        ><v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn rounded outlined x-large class="text-capitalize secondary--text">
          <span class="font-weight-bold text-h4 mr-2">G</span> Google
        </v-btn></v-col
      >
    </v-row> -->

    <v-footer color="transparent" class="primary--text" v-if="isMobile">
      <v-row class="mb-6">
        <v-col class="text-center">
          <span class="text-subtitle-1">
            {{ $t("login.new") }}
            <a
              class="font-weight-bold text-decoration-underline"
              @click="redirectRegister"
            >
              {{ $t("register.here") }}
            </a>
          </span>
        </v-col>
      </v-row>
    </v-footer>

    <!-- isMobile -->

    <!-- !isMobile -->
    <div class="pa-0 ma-0" v-if="!isMobile">
      <v-row
        class="background-image pa-0 ma-0 ba-0 height-row-1"
        justify="center"
      >
        <v-row justify="center" class="height-principal">
          <v-col cols="10" class="pa-0 ma-0 ba-0">
            <v-row class="pa-0 ma-0 ba-0" justify="start">
              <div class="d-flex pt-10">
                <v-img
                  alt="ApoTalent Logo"
                  class="shrink mr-2"
                  contain
                  src="@/assets/apotalent/Isotipo white.png"
                  transition="scale-transition"
                  width="95"
                />
                <v-img
                  alt="ApoTalent Name"
                  class="shrink mt-1"
                  contain
                  src="@/assets/apotalent/ApoTalent.svg"
                  transition="scale-transition"
                  width="170"
                />
              </div>
            </v-row>
            <v-row class="px-0 ma-0 ba-0" justify="center">
              <v-col cols="5" class="ma-0 pa-0">
                <p class="primary--text title-styles mt-10">¡Bienvenido!</p>
                <p class="black--text title-body mt-10 pr-10">
                  Te damos la bienvenida a tu plataforma profesional de talento
                  para la farmacia comunitaria.
                </p>
              </v-col>
              <v-col cols="5" class="ma-0 pa-0">
                <div class="white rounded-lg pa-2">
                  <v-row class="pa-0 ma-0" justify-sm="center">
                    <v-col cols="9" class="pa-0">
                      <p class="title-action my-8">{{ $t("login.title") }}</p>
                      <v-text-field
                        :label="$t('field.email')"
                        color="primary"
                        :rules="emailRules"
                        validate-on-blur
                        required
                        outlined
                        v-model="user.email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 ma-0" justify-sm="center">
                    <v-col cols="9" class="pa-0">
                      <v-text-field
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        :hint="$t('validation.password')"
                        :label="$t('field.password')"
                        outlined
                        :hide-details="false"
                        @keypress.enter="onLogin"
                        v-model="user.password"
                      >
                        <v-icon
                          slot="append"
                          color="primary"
                          @click="show1 = !show1"
                          >{{ show1 ? "mdi-eye" : "mdi-eye-off" }}</v-icon
                        >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pa-0 ma-0" justify="center">
                    <v-col cols="9" class="pa-0 mo-0 mt-2" justify="center">
                      <a @click="redirectChangePassword" style="opacity: 0.6">{{
                        $t("password.forget")
                      }}</a>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 my-6" justify="center">
                    <v-col cols="8" class="pa-0 mt-2 mr-0 text-center">
                      <div>
                        <v-btn
                          color="secondary"
                          style="text-capitalize text-h6 font-weight-light"
                          block
                          large
                          rounded-1
                          @click.prevent="onLogin"
                        >
                          {{ $t("login.title") }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <v-row class="my-3">
                  <v-col class="text-center">
                    <span class="text-subtitle-1">
                      {{ $t("login.new") }}
                      <a
                        class="font-weight-bold text-decoration-underline"
                        @click="redirectRegister"
                      >
                        {{ $t("register.here") }}
                      </a>
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="height-footer">
          <v-col cols="12" class="pa-0 mx-0 my-0 d-flex white">
            <div class="wrap-width ma-0 d-flex justify-end">
              <div class="wrap-text py-6 pl-4 pr-2">
                <p class="title-selection">Para candidatos</p>
                <p class="text-selection">
                  En Apotalent, te ayudamos a encontrar el empleo en la farmacia
                  más adecuada para ti
                </p>
                <ul class="text-selection">
                  <li>Potenciamos tu perfil</li>
                  <li>Sacamos tu mejor versión</li>
                </ul>
              </div>
            </div>
            <div class="wrap-width ma-0 d-flex justify-start">
              <div class="wrap-text py-6 pl-8 pr-2">
                <p class="title-selection">Para farmacias</p>
                <p class="text-selection">
                  En Apotalent, ponemos a tu disposición dos servicios de
                  preselección:
                </p>
                <ul class="text-selection">
                  <li>Alquimia, selección automatizada</li>
                  <li>Magister, análisis psicosocial de candidatos</li>
                </ul>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="bg-green-footer px-0 pt-2 pb-6 ma-0 d-flex justify-center"
          >
            <ul class="d-flex">
              <ol class="pt-5">
                <a class="footer-options" @click="redirectFaqs"
                  >Términos y condiciones</a
                >
              </ol>
              <ol class="pt-5">
                <a class="footer-options" @click="redirectFaqs"
                  >Políticas de privacidad</a
                >
              </ol>
              <ol class="pt-5">
                <a class="footer-options" @click="redirectContact">FAQS</a>
              </ol>
              <ol class="pt-5">
                <a class="footer-options" @click="redirectContact">Ayuda</a>
              </ol>
              <!-- <ol class="footer-options wrap-languages pt-3">
                <v-select
                  v-model="select"
                  class="wrap-options pa-0"
                  :items="items"
                  item-text="language"
                  item-value="flat"
                  dense
                  return-object
                  single-line
                ></v-select>
              </ol> -->
            </ul>
          </v-col>
        </v-row>
      </v-row>
    </div>
    <!-- !isMobile -->
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import MainLayout from "@/components/layouts/Main.vue";
import ContinueRegister from "../components/modals/ContinueRegister.vue";

export default {
  components: {
    MainLayout,
    ContinueRegister,
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      notRegistered: false,
      rememberme: false,
      show1: false,
      select: { language: "Español", flat: "ES" },
      items: [
        { language: "Español", flat: "ES" },
        { language: "Inglés", flat: "EN" },
        { language: "Portugués", flat: "PO" },
        { language: "Catalán", flat: "CA" },
      ],
      userTypes: [
        "pharmacy",
        "candidate",
        "expert",
        "alquimia",
        "pharmacygroup",
      ],
      emailRules: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) => /.+@.+/.test(v) || this.$i18n.t("validation.email_format"),
      ],
    };
  },
  computed: {
    ...mapGetters({ userType: "user/type" }),
    ...mapGetters({ userInfo: "user/info" }),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
        ? true
        : false;
    },
  },
  methods: {
    ...mapActions({ login: "user/login" }),
    ...mapActions({ logout: "user/logout" }),
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    async onLogin() {
      this.enableLoading();
      try {
        await this.login(this.user);
        if (this.userTypes.includes(this.userType)) {
          this.$acl.change(this.userType);
          this.$notify({
            title: this.$i18n.t("login.sucessfull_title"),
            text: this.$i18n.t("login.sucessfull"),
            type: "success",
          });
          this.$router.push({ name: "offers" });
        } else {
          this.notRegistered = true;
        }
      } catch (error) {
        Object.values(error.response.data).forEach((elm) => {
          this.$notify({
            title: this.$i18n.t("login.error_title"),
            text: Array.isArray(elm) ? elm.join(", ") : elm,
            type: "error",
          });
        });
      } finally {
        this.disableLoading();
      }
    },
    redirectRegister() {
      this.$router.push({ name: "register" });
    },
    redirectChangePassword() {
      this.$router.push({ name: "forgot-password" });
    },
    redirectContact() {
      this.$router.push({ name: "contact" });
    },
    redirectFaqs() {
      this.$router.push({ name: "faqs" });
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined fieldset {
  border-color: #718f94 !important;
}
.v-btn border {
  border-color: #718f94;
}
.v-text-field--outlined fieldset {
  border-color: #718f94 !important;
}
.v-btn border {
  border-color: #718f94;
}
.background-image {
  background-image: url("~@/assets/apotalent/loginapo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1000px !important;
  padding-top: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  background-position: top center;
}
.title-styles {
  font-size: 45px;
}
.title-body {
  font-size: 25px;
}
.title-heigth {
  height: 5%;
}
.body-height {
  height: 40%;
}
.wrap-login {
  border: 3px;
  background: white;
}
.bg-green-footer {
  background: #9ab4b8;
}
.height-row-1 {
  height: 820px !important;
}
.wrap-width {
  width: 50%;
  border-right: solid #9ab4b8 1px;
}
.wrap-text {
  width: 60%;
}
.title-selection {
  color: black;
  font-size: 24px;
  font-weight: bold;
}
.text-selection {
  font-size: 20px;
}
.footer-options {
  margin-left: 40px;
  margin-right: 40px;
  font-size: 18px;
}
.title-action {
  color: black;
  font-size: 19px;
  font-weight: bold;
}
.wrap-under {
  text-decoration: underline;
}
.wrap-languages {
  width: 200px;
}

.footer-options .v-input {
  font-size: 18px !important;
}
.footer-options {
  border: 0px !important;
}
.footer-options .v-text-field > .v-input__control > .v-input__slot::before {
  border-style: none !important;
}
.fixed-footer {
  position: absolute !important;
  bottom: 0px !important;
}
.height-principal {
  height: 50% !important;
}
.height-footer {
  position: absolute !important;
  height: 30% !important;
  bottom: -23px !important;
}
</style>
